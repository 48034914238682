import { useMount, useUnmount } from 'react-use';

import { Event } from '../utility/event';

export const useEventSubscribe = ( callbacks: string | any[] ) =>
{
	useMount( () =>
	{
		for( let i = 0; i < callbacks.length; i++ )
		{
			Event.subscribe( callbacks[ i ][ 0 ], callbacks[ i ][ 1 ], callbacks[ i ][ 2 ] );
		}
	});

	useUnmount( () =>
	{
		for( let i = 0; i < callbacks.length; i++ )
		{
			Event.unsubscribe( callbacks[ i ][ 0 ], callbacks[ i ][ 1 ], callbacks[ i ][ 2 ] );
		}
	});
};
