export const FONT_FAMILIY_DEFAULT		= [ `Hiragino Sans`,`Hiragino Kaku Gothic Pro`,`Meiryo`,`Helvetica Neue`,`Roboto`,`Droid Sans`,`Arial`,`sans-serif` ];

export const CONTENT_WIDTH				= 800;
export const BREAKPOINT_SP				= 650;
export const BREAKPOINT_TB				= 960;
export const HEADER_HEIGHT				= 40;
export const MENU_WIDTH					= 400;

export const COLOR_FANZTOK_MAIN			= `#FE005B`;
export const COLOR_TWITTER				= `#1DA1F2`;
export const COLOR_GRADATION			= `#E4A972`;
export const COLOR_BLACK				= `#14171A`;
export const COLOR_DARK_GRAY			= `#657786`;
export const COLOR_LIGHT_GRAY			= `#AAB8C2`;
export const COLOR_EXTRA_LIGHT_GRAY		= `#E1E8ED`;
export const COLOR_ULTRA_LIGHT_GRAY		= `#F5F8FA`;

export const URL_TWITTER				= `https://twitter.com/fanz_tok`;
export const URL_NOTICE					= `https://twitter.com/search?q=fanz_tokお知らせ`;
export const URL_TERM					= `https://www.google.co.jp`;
export const URL_PRIVACY				= `https://www.google.co.jp`;
export const URL_INQUIRY				= `https://twitter.com/fanz_tok`;

export const SCROLL_HANDLE_WIDTH		= 5;
export const HOME_TIMELINE_ITEM_COUNT	= 10;
export const TWEET_TIMELINE_ITEM_COUNT	= 50;

export const FANZAMAN_ICON_URLS =
[
	`https://firebasestorage.googleapis.com/v0/b/fanza-man.appspot.com/o/DALL%C2%B7E%202023-03-09%2017.31.20%20-%20the%20Japanese%20hero%20with%20a%20bandana%20wrapped%20around%20his%20head%20and%20a%20diagonal%20face%20in%20a%20photograph.jpg?alt=media&token=0b83d96c-abdc-4bec-93a9-a28dd23d5ae4`,
	`https://firebasestorage.googleapis.com/v0/b/fanza-man.appspot.com/o/DALL%C2%B7E%202023-03-09%2017.31.29%20-%20the%20Japanese%20hero%20with%20a%20bandana%20wrapped%20around%20his%20head%20and%20a%20diagonal%20face%20in%20a%20photograph.jpg?alt=media&token=6cc1b40e-b379-4f58-98c0-d74730135e92`,
	`https://firebasestorage.googleapis.com/v0/b/fanza-man.appspot.com/o/DALL%C2%B7E%202023-03-09%2017.31.39%20-%20the%20Japanese%20hero%20with%20a%20bandana%20wrapped%20around%20his%20head%20and%20a%20diagonal%20face%20in%20a%20photograph.jpg?alt=media&token=7f99d5bc-b0d0-4d18-b64f-98d287a0f553`,
	`https://firebasestorage.googleapis.com/v0/b/fanza-man.appspot.com/o/DALL%C2%B7E%202023-03-09%2017.32.10%20-%20the%20Japanese%20hero%20with%20a%20bandana%20wrapped%20around%20his%20head%20and%20a%20diagonal%20face%20in%20a%20photograph.jpg?alt=media&token=4a103bdc-b7f1-480f-8ee0-ed75cf01b4c7`,
	`https://firebasestorage.googleapis.com/v0/b/fanza-man.appspot.com/o/DALL%C2%B7E%202023-03-09%2017.32.42%20-%20the%20Japanese%20hero%20with%20a%20bandana%20wrapped%20around%20his%20head%20and%20a%20diagonal%20face%20in%20a%20photograph.jpg?alt=media&token=e7a72750-b220-4f0a-a279-ee21cb2e514c`,
	`https://firebasestorage.googleapis.com/v0/b/fanza-man.appspot.com/o/DALL%C2%B7E%202023-03-09%2017.33.59%20-%20the%20Japanese%20hero%20with%20a%20bandana%20wrapped%20around%20his%20head%20and%20a%20diagonal%20face%20in%20a%20photograph.jpg?alt=media&token=54566f60-0222-4ed1-b061-13f97cce0340`,
	`https://firebasestorage.googleapis.com/v0/b/fanza-man.appspot.com/o/DALL%C2%B7E%202023-03-09%2017.34.21%20-%20the%20Japanese%20hero%20with%20a%20bandana%20wrapped%20around%20his%20head%20and%20a%20diagonal%20face%20in%20a%20photograph.jpg?alt=media&token=7390f2a7-a301-4fb4-9cf7-1c2e9ca21c5c`,
	`https://firebasestorage.googleapis.com/v0/b/fanza-man.appspot.com/o/DALL%C2%B7E%202023-03-09%2017.35.56%20-%20the%20photograph%20of%20the%20Japanese%20hero%20with%20a%20bandana%20wrapped%20around%20his%20head%20and%20a%20surprised%20diagonal%20face.jpg?alt=media&token=c3f2f34a-2894-4e7d-85b7-e658f055a614`,
	`https://firebasestorage.googleapis.com/v0/b/fanza-man.appspot.com/o/DALL%C2%B7E%202023-03-09%2017.36.00%20-%20the%20photograph%20of%20the%20Japanese%20hero%20with%20a%20bandana%20wrapped%20around%20his%20head%20and%20a%20surprised%20diagonal%20face.jpg?alt=media&token=4c7683bd-ca32-4b09-90ba-7cfde2b9c728`,
	// `https://firebasestorage.googleapis.com/v0/b/fanza-man.appspot.com/o/DALL%C2%B7E%202023-03-09%2017.38.40%20-%20the%20anime%20illustration%20of%20the%20Japanese%20hero%20with%20a%20red%20bandana%20wrapped%20around%20his%20head%20and%20a%20surprised%20diagonal%20face.jpg?alt=media&token=1eb25ccf-7589-4da8-b010-7110d17a5754`,
	`https://firebasestorage.googleapis.com/v0/b/fanza-man.appspot.com/o/DALL%C2%B7E%202023-03-09%2017.53.20%20-%20the%20Japanese%20hero%20with%20a%20bandana%20wrapped%20around%20his%20head%20and%20a%20diagonal%20face.jpg?alt=media&token=404060d1-939a-4c83-b047-d6800086653a`,
	`https://firebasestorage.googleapis.com/v0/b/fanza-man.appspot.com/o/DALL%C2%B7E%202023-03-09%2017.53.24%20-%20the%20Japanese%20hero%20with%20a%20bandana%20wrapped%20around%20his%20head%20and%20a%20diagonal%20face.jpg?alt=media&token=fa6905ac-3097-465b-9f49-b518d2aa3496`,
	`https://firebasestorage.googleapis.com/v0/b/fanza-man.appspot.com/o/DALL%C2%B7E%202023-03-09%2017.53.50%20-%20the%20Japanese%20hero%20with%20a%20bandana%20wrapped%20around%20his%20head%20and%20a%20diagonal%20face%20in%20a%20photograph.jpg?alt=media&token=1fa4f7e8-3a00-4bc3-84a4-80676ffb87b5`,
	`https://firebasestorage.googleapis.com/v0/b/fanza-man.appspot.com/o/DALL%C2%B7E%202023-03-09%2017.53.58%20-%20the%20Japanese%20hero%20with%20a%20bandana%20wrapped%20around%20his%20head%20and%20a%20diagonal%20face%20in%20a%20photograph.jpg?alt=media&token=663cd16d-e18b-4ca2-9c53-d03a44678e58`,
	`https://firebasestorage.googleapis.com/v0/b/fanza-man.appspot.com/o/DALL%C2%B7E%202023-03-09%2017.54.06%20-%20the%20Japanese%20hero%20with%20a%20bandana%20wrapped%20around%20his%20head%20and%20a%20diagonal%20face%20in%20a%20photograph.jpg?alt=media&token=81ea3f1f-56f7-4a89-8fcd-b3a9cf7b1402`,
];

export const WELCOME_MESSGES =
[
	`お、新しい客か。よっ、FANZAマンだ。`,
	`いらっしゃい。FANZAマンがなんでも紹介してやる。`,
	`へへ、何か欲しいものでもあるか？FANZAマンに聞いてみろよ。`,
	`ああ、またお前か。FANZAマンが対応してやるから、なんでも言ってみろ。`,
	`お前さん、ここは一番エロいところだと知ってるのか？FANZAマンが案内してやる。`,
	`何か買い物でもするつもりか？FANZAマンが案内してやるぜ。`,
	`いらっしゃい、いらっしゃい。FANZAマンがなんでも紹介してやるから、まずは好みでも言ってみなよ。`,
	`ほう、エッチなものに興味があるんだな。FANZAマンが一番いいものを紹介してやるぜ。`,
	`何か欲しいものでもあるか？FANZAマンが一番いいものを紹介してやる。`,
	`ははーん、お前もエッチなものが好きなんだな。FANZAマンが一番いいものを紹介してやるよ。`,
	`いらっしゃい、お前。ここはエッチなものがいっぱいある場所だ。FANZAマンが手伝ってやるから、なんでも言ってみろよ。`,
	`よう、新しい客か。FANZAマンが一番エロいものを紹介してやるから、期待していいぜ。`,
	`おう、ここは一番エロいところだと知ってたか？FANZAマンが案内してやるから、好きなものでも言ってみな。`,
	`よう、俺はFANZAマンだぜ。`,
	`お前、さっそくエッチな気分か？ははっ、冗談だ。`,
	`こんなとこで何してんだ？エロ動画でも探してるのか？俺に任せろ。`,
	`おいおい、お前もうここにいる時間じゃないだろうに。仕事でもサボってんのか？`,
	`いい加減、仕事に集中しろよ。エロに没頭するとろくなことがないぜ。`,
	`お前もよくここに来るな。俺と一緒にエッチなやつを探しに行こうぜ。`,
	`はっ、お前もFANZAマンに会いたかったのか？当然だ。俺はFANZAマンだからな。`,
	`よっ、お前もまたFANZAマンの元へ来たか。エロ動画でも探しに来たのか？`,
	`お前もまたFANZAマンに会いたかったんだろ？ははっ、冗談だ。`,
	`おいおい、仕事ははかどってるのか？エッチなことばかりしてると駄目だぜ。`,
	`お前もまたFANZAマンに会いに来たのか？俺はいつでもお前をエロい世界に導いてやるぜ。`,
	`おいおい、お前の仕事は進んでるんだろうな？エロに没頭しすぎるとろくなことがないぞ。`,
	`お前もまたFANZAマンに会いたかったんだろ？ははっ、冗談だ。`,
	`おいおい、お前のエッチな趣味はいいけど、ちゃんと仕事もやってるんだろうな？`,
];

export const QUESTION_MESSAGES =
[
	`お前が好きな女優さんは誰だ？俺に教えてくれ。`,
	`巨乳系の女優さんが好きなのか？それとも貧乳系がいいのかな？`,
	`今一番人気のある女優さんを知りたいのか？俺に任せてくれ。`,
	`最近、人気が出てきている女優さんを知りたいのか？俺に任せてくれ。`,
	`ロリ系の女優さんが好きなのか？あるいは熟女系がいいのかな？`,
	`お前が今まで見た中で一番好きな女優さんは誰だった？`,
	`色白美人系の女優さんが好きなのか？それとも黒ギャル系がいいのかな？`,
	`ナンパ系の女優さんが好きなのか？あるいは企画系がいいのかな？`,
	`もし見たい女優さんがいたら、俺に教えてくれ。それに合わせた作品を探してやるよ。`,
	`どんなジャンルが好きなのか教えてくれ。そこから探し始めよう。`,
	`女優さんの名前から探したいのか？それともジャンルから探したいのか？`,
	`興奮するプレイがあるのか？それとも、可愛い女優さんがいいのかな？`,
	`何か特定のシチュエーションで見たいのか？それとも、普通のプレイがいいのかな？`,
	`今見たいと思っている女優さんの特徴を教えてくれ。それにあった作品を探してやるよ。`,
	`お前が好きな女優さんのタイプは何だ？それに合わせた作品を探してみよう。`,
	`今一番人気の女優さんが気になるのか？それとも、マイナーな女優さんがいいのかな？`,
	`お前が今まで見た中で一番興奮したジャンルは何だった？`,
	`女優さんのルックスに拘りがあるのか？それともプレイに拘りがあるのかな？`,
	`お前が好きな女優さんの特徴を教えてくれ。それにあった作品を探してやるよ。`,
	`もし、今すぐに見たいと思っている作品があるなら、俺に教えてくれ。`,
	`何か特定のフェチがあるのか？それとも普通の作品がいいのかな？`,
	`女優さんの年齢にこだわりがあるのか？それとも気にしない方がいいのかな？`,
	`どんな作品が見たいのか、詳しく教えてくれ。その情報を元に、探し始めよう。`,
	`お前が好きな女優さんの作品を探してみよう。それに合わせた作品を探してやるよ。`,
	`激しいプレイが見たいのか？それともソフトなプレイがいいのかな？`,
	`ハメ撮り系の作品が好きなのか？それとも企画物がいいのかな？`,
	`お前が見たい作品のシチュエーションを詳しく教えてくれ。それにあった作品を探してやるよ。`,
	`もし、今すぐに見たいと思っている作品があるなら、俺に教えてくれ。`,
	`もし、お前が今まで見た中で一番好きな女優さんがいるなら、その女優さんの作品を探してやるよ。`,
	`淫語系の作品が好きなのか？それとも、ローションプレイがいいのかな？`,
];

export const BAD_REQUEST_MESSGES =
[
	`そんな曖昧な要望ではどこまで行っても満足できないぞ！もっと具体的に言ってくれ！`,
	`お前が欲しいものが何か、俺にも分からないぞ。もっと具体的に言ってくれないと、探すのも苦労するぞ。`,
	`そんな抽象的な要望では、俺には何を探せばいいか分からないぞ。もっと具体的に言ってくれ！`,
	`お前が欲しいものが何か、俺には分からない。もっと具体的に言ってくれないと、探すのも苦労するぞ。`,
	`もっと具体的に言ってくれないと、お前に合った作品を見つけるのは難しいぞ！`,
	`俺にはお前が欲しいものが分からない。もっと具体的に言ってくれないと、探すのも苦労するぞ。`,
	`そんな漠然とした要望では、お前が求める作品を見つけることはできないぞ！もっと具体的に言ってくれ！`,
	`お前が欲しいものは何か、俺には分からない。もっと具体的に言ってくれないと、探すのも苦労するぞ。`,
	`もっと具体的に言ってくれないと、お前に合った作品を見つけることはできないぞ！`,
	`お前が欲しいものが何か、俺には分からない。もっと具体的に言ってくれないと、探すのも苦労するぞ。`,
	`そんな曖昧な要望では、俺には何を探せばいいか分からないぞ。もっと具体的に言ってくれ！`,
	`もっと具体的に言ってくれないと、お前の欲望を満たす作品を見つけることはできないぞ！`,
	`お前が欲しいものが何か、俺には分からない。もっと具体的に言ってくれないと、探すのも苦労するぞ。`,
	`そんな抽象的な要望では、お前が求める作品を見つけることはできないぞ！もっと具体的に言ってくれ！`,
	`もっと具体的に言ってくれないと、お前に合った作品を見つけることはできないぞ！`,
	`お前が欲しいものが何か、俺には分からない。もっと具体的に言ってくれないと、探すのも苦労するぞ。`,
	`そんな漠然とした要望では、お前が求める作品を見つけることはできないぞ！もっと具体的に言ってくれ！`,
	`もっと具体的に言ってくれないと、お前の欲望を満たす作品を見つけることはできないぞ！`,
	`お前が欲しいものが何か、俺には分からない。もっと具体的に言ってくれないと、探すのも苦労するぞ。`,
	`そんな曖昧な要望では、お前が求めるものを見つけることはできないぞ！もっと具体的に言ってくれ！`,
	`お前が欲しいものが何か、俺にも分からない。もっと具体的に言ってくれないと、探すのも苦労するぞ。`,
	`そんな抽象的な要望では、お前が求めるものを見つけることはできないぞ！もっと具体的に言ってくれ！`,
	`もっと具体的に言ってくれないと、お前の欲望を満たす作品を見つけることはできないぞ！`,
	`お前が欲しいものが何か、俺には分からない。もっと具体的に言ってくれないと、探すのも苦労するぞ。`,
	`そんな漠然とした要望では、お前が求める作品を見つけることはできないぞ！もっと具体的に言ってくれ！`,
	`もっと具体的に言ってくれないと、お前に合った作品を見つけることはできないぞ！`,
	`お前が欲しいものが何か、俺には分からない。もっと具体的に言ってくれないと、探すのも苦労するぞ。`,
	`そんな曖昧な要望では、お前が求めるものを見つけることはできないぞ！もっと具体的に言ってくれ！`,
	`もっと具体的に言ってくれないと、お前の欲望を満たす作品を見つけることはできないぞ！`,
	`お前が欲しいものが何か、俺には分からない。もっと具体的に言ってくれないと、探すのも苦労するぞ。`,
];

export const START_MESSGES =
[
	`ヨシ、わかったぜ。`,
	`オッケー、聞き入れたよ。`,
	`よっしゃ、理解したぜ。`,
	`あー、わかったわかった。`,
	`了解、対応するよ。`,
	`おーけー、対応するわ。`,
	`わかった、改善策を考えるよ。`,
	`んじゃ、聞き入れたってことで。`,
	`わかった、すぐにやるよ。`,
	`了承、すぐに手配するわ。`,
	`わかったよ、問題解決に全力を尽くす。`,
	`オッケー、すぐに対応するわ。`,
	`ふむふむ、了解しました。`,
	`わろた、聞き入れたで。`,
	`あー、了解したわ。`,
	`んじゃ、対応するわ。`,
	`よっしゃ、すぐに改善するよ。`,
	`おーけー、おっしゃるとおりにします。`,
	`あー、わかったわかった。`,
	`わかった、必要な手続きを進めるよ。`,
	`了解、問題解決に向けて全力でやるよ。`,
	`おーけー、迅速に対応するわ。`,
	`よっしゃ、聞き入れたぜ。`,
	`んじゃ、すぐに行動するよ。`,
	`わかった、ご要望に添えるように頑張るよ。`,
];

export const WAIT_MESSGES =
[
	`いいだろう、俺が紹介するから、少し待ってくれよ。`,
	`おいおい、そんなに急いでどうするんだ？俺が用意するから、待ってくれよ。`,
	`待ってくれって言っても、あまり時間はかからねーぞ。`,
	`ちょっと待ってくれよ。用意するから、その辺で待ってろよ。`,
	`もうちょっと待ってくれってばかりだな。俺のセンスを信じて待ってくれよ。`,
	`待ってくれって言われるのは嫌いじゃねえが、それでもちょっと待ってくれよ。`,
	`おいおい、待ってくれよ。早くても1分くらいはかかるからな。`,
	`ちょっと待ってくれよ。ここでぼーっとしててくれ。`,
	`待ってくれって言うのもアレだが、ちょっとだけ待っててくれよ。`,
	`待ってくれよ。もう少しで準備できるから。`,
	`ちょっと待ってくれって言われるのも、たまには必要だろう？`,
	`おいおい、待ってくれよ。そんなに焦らなくてもいいんじゃねえか？`,
	`ちょっと待ってくれよ。俺が用意するから、ゆっくり待ってろよ。`,
	`ちょっと待ってくれよ。ここで待っててくれ。間もなく用意するから。`,
	`待ってくれって言うのも、俺の仕事だからな。そこまで急がなくてもいいんじゃねえか？`,
	`おいおい、少し待ってくれよ。そんなに急がなくてもいいだろう？`,
	`ちょっと待ってくれよ。あまり時間はかからないから、ゆっくり待っててくれよ。`,
	`少し待ってくれよ。まもなく、用意ができるからな。`,
	`待ってくれって言うのも、俺の仕事だからな。ちょっとだけ待っててくれよ。`,
	`おいおい、少し待ってくれよ。急いでどこへ行くつもりなんだ？`,
	`待ってくれって言われても、焦らずに待ってくれよ。あと少しで用意ができるから。`,
	`ちょっと待ってくれよ。用意するものがあるから、それまで少し待って`,
];

export const ERROR_MESSGES =
[
	`ちょっとまってくれ。エラーが出たみたいだ。`,
	`調子が悪いな。もう一度やり直してくれよ。`,
	`エラーが出たらしく、もう一度やり直してくれって言ってるだろ？`,
	`もう一度やり直すしかないみたいだ。しばらく待ってから試してくれ。`,
	`エラーが出てるみたいだ。しばらく待ってからもう一度やり直してくれ。`,
	`もう一度試すしかないな。しばらく待ってからやり直してくれ。`,
	`もう一度やり直してくれ。エラーが出てるから、少し待ってから試してくれ。`,
	`調子が悪いな。もう一度やり直してくれよ。`,
	`エラーが出てるから、しばらく待ってからもう一度試してくれ。`,
	`エラーが出たみたいだ。もう一度試すしかないな。`,
	`エラーが起きてるようだな。もう一度やり直せ。`,
	`ちょっと待て、調子が悪いらしい。再度試せ。`,
	`なんだ、エラーが出ているぞ。もう一度チャレンジしろ。`,
	`なんだ、このエラーは。待ってからもう一度試してくれ。`,
	`エラーだな。すまんが、もう一度やり直してくれよ。`,
	`ちょっと待て。エラーだって、そう言うんだろう？　再度挑戦しろ。`,
	`調子が悪いな。少し待ってから再度試してくれ。`,
	`エラーが出ているぞ。もう一度やり直せ。`,
	`調子わりい。ほんの少し待ってから、もう一度チャレンジしろ。`,
	`調子が悪いな。エラーか何かか？　もう一度やり直してくれ。`,
	`もう一度やり直せ。エラーが出ているようだ。`,
	`なんだ、このエラーは。再度挑戦しろ。`,
	`エラーが出たみたいだな。もう一度試せ。`,
	`調子が悪いようだ。もう一度挑戦しろ。`,
	`待ってくれ、エラーが出ているみたいだ。再度試してくれ。`,
	`このエラーは何だ。もう一度チャレンジしてくれよ。`,
	`エラーが起きているようだな。再度挑戦しろ。`,
];

export const OK_MESSGES =
[
	`これでどうだ！お前が求めるエロスを全部詰め込んでやったぜ。`,
	`お前にピッタリの作品を探してやったよ。これでどうだ？`,
	`ふふ、ここにある作品はお前が欲しいものばかりだ。どうだ？`,
	`これでどうだ！俺が紹介する作品には、お前の欲望を刺激する要素がたっぷりだぜ。`,
	`これでどうだ！俺が選んだ作品なら、お前のヌキどころを的確に捉えているぜ。`,
	`お前が探していた作品を見つけ出してやったよ。これでどうだ？`,
	`これでどうだ！俺が紹介する作品には、お前が求めるエロスがたっぷりと詰まっているぜ。`,
	`お前が欲しがる作品を見つけ出してやったぜ。どうだ、満足だろう？`,
	`これでどうだ！俺が厳選した作品なら、お前もきっと納得するはずだ。`,
	`これでどうだ！お前が求めるエロスを、俺が全部詰め込んでやったぜ。`,
	`俺が選んだ作品なら、お前も納得できるはずだ。どうだ？`,
	`これでどうだ！お前にピッタリの作品を紹介してやった。さあ、楽しんでくれよ。`,
	`これでどうだ！俺が選んだ作品は、お前が求めるエロスが詰まった最高のものばかりだ。`,
	`これでどうだ！俺が紹介する作品なら、お前のヌキどころを的確に捉えているぜ。`,
	`これでどうだ！俺が厳選した作品は、お前の欲望を満たすために存在しているようなものばかりだ。`,
	`お前が求めるエロスを、俺が全部詰め込んでやった。どうだ、興奮してきたか？`,
	`これでどうだ！俺が選んだ作品は、お前が探していたものとぴったりマッチするはずだ。`,
	`お前が欲しい作品はここにある。どうだ、選ぶのが楽しいだろ？`,
	`お前が探していた作品はこれだろう？どうだ、合ってるか？`,
	`これでどうだ！俺が厳選した作品は、お前が求めるエロスをすべて満たしてくれるはずだ。`,
	`お前が欲しい作品はここにある。どうだ、選ぶのに迷うくらい豊富だろ？`,
	`これでどうだ！俺が選んだ作品は、お前の欲望を刺激するために生まれた究極の作品ばかりだ。`,
	`これでどうだ！俺が紹介する作品は、お前の欲望を満たすために生まれた作品ばかりだ。`,
	`お前が欲しい作品はこれだろう？どうだ、当たってるか？`,
	`これでどうだ！俺が選んだ作品なら、お前もきっと納得するはずだ。`,
	`これでどうだ！俺が紹介する作品は、お前の欲望を刺激するために生まれた作品ばかりだ。`,
	`お前が欲しい作品はここにある。どうだ、満足するものが見つかったか？`,
];