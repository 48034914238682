import { css } from '@emotion/react';
import { memo, useCallback } from 'react';
import { navigate } from '@reach/router';
import { Tween24, Ease24 } from "tween24";
import { useMount, useUpdate } from 'react-use';
import { v4 as uuidv4 } from 'uuid';

import
{
	COLOR_EXTRA_LIGHT_GRAY,
	COLOR_FANZTOK_MAIN,
	CONTENT_WIDTH,
	HEADER_HEIGHT,
	MENU_WIDTH,
} from '../utility/const';
import
{
	getCategoryList,
	getClientWidth,
	getFanzaCoupon,
	getSessionStorage,
	getUniqueKey,
	getUrlParams,
	isSmartphone,
	shuffleArray,
	toInteger,
} from '../utility/utility';
import { useLocalState } from '../hook/use-local-state';
import { useEventSubscribe } from '../hook/use-event-subscribe';

Tween24.setDefaultEasing( Ease24._6_ExpoOut );

const Layout = memo( ( params: any ) =>
{
	return (
		<aside css={params.style} id={`side-menu`}>
			<div className={`side-menu-line`}></div>
			<div className={`side-menu-base`}>
				{params.items}
			</div>
		</aside>
	);
});

const Style = ( params: any ) => css`
	position				: fixed;
	@media screen and ( max-width : calc( 650px - 1px ) )
	{
		left				: 0px;
	}
	@media screen and ( min-width : 650px )
	{
		left				: 0px;
	}
	top						: 0px;
	width					: ${params.width}px;
	height					: 100vh;
	background-color		: white;
	scrollbar-width			: none;
	border-right			: 1px solid ${COLOR_EXTRA_LIGHT_GRAY};

	.side-menu-base
	{
		position			: relative;
		left				: 0px;
		top					: 0px;
		width				: calc( ${params.width}px - 16px );
		overflow-x			: hidden;
		overflow-y			: auto;
		padding-left		: 0px;
		@media screen and ( max-width : calc( 650px - 1px ) )
		{
			margin-left		: 8px;
			margin-right	: 8px;
			margin-bottom	: 8px;
			padding-top		: ${HEADER_HEIGHT}px;
			padding-bottom	: ${HEADER_HEIGHT}px;
			height			: calc( 100vh - ${HEADER_HEIGHT}px - 8px - 8px );
		}
		@media screen and ( min-width : 650px )
		{
			margin-left		: 16px;
			margin-right	: 16px;
			margin-bottom	: 16px;
			padding-top		: ${HEADER_HEIGHT}px;
			padding-bottom	: ${HEADER_HEIGHT}px;
			height			: calc( 100vh - ${HEADER_HEIGHT}px - 16px - 16px );
		}
	}
	.side-menu-base::-webkit-scrollbar
	{
		display				: none;
	}
	.side-menu-line
	{
		position			: absolute;
		top					: 0px;
		z-index				: 1000;
		width				: 100%;
		height				: ${HEADER_HEIGHT}px;
		background-color	: white;
	}
	.side-menu-item-base
	{
		position			: relative;
		width				: 100%;
		text-align			: center;
		padding-top			: 20px;
		margin				: 0;
	}
	.side-menu-item-name
	{
		position			: relative;
		/* font-weight			: */
		font-size			: 14px;
		padding-bottom		: 8px;
	}
`;

export const SideMenu = ( props: any ) =>
{
	const state = useLocalState(
	{
		isInit	: false,
		isShow	: false,
		width	: 0,
		items	: [],
	});
	const update = useUpdate();

	const updateMenuWidth = useCallback( () =>
	{
		if( isSmartphone() )
		{
			state.width = getClientWidth();
		}
		else
		{
			state.width = MENU_WIDTH;
		}
		update();
	}, [ state.width, state.isShow, update ] );

	const initMenu = useCallback( () =>
	{
		state.isInit = true;
		const target = document.getElementById( `side-menu` );
		if( isSmartphone() )
		{
			state.isShow = false;
			Tween24.tween( target, 0.0, Ease24._BackOut ).x( -getClientWidth() ).willChange().skip();
		}
		else
		{
			state.isShow = false;
			Tween24.tween( target, 0.0, Ease24._BackOut ).x( -MENU_WIDTH ).willChange().skip();
		}
		updateMenuWidth();
	}, [ state.isInit, state.isShow, state.width, update ] );

	const openMenu = useCallback( () =>
	{
		const target = document.getElementById( `side-menu` );
		Tween24.tween( target, 0.3, Ease24._6_ExpoOut ).x( 0 ).willChange().play();
		state.isShow = true;
	}, [ state.isShow ] );

	const closeMenu = useCallback( ( params: any ) =>
	{
		if( params.force === false )
		{
			if( 1050 < getClientWidth() )
			{
				return;
			}
		}
		const target = document.getElementById( `side-menu` );
		Tween24.tween( target, 0.3, Ease24._6_ExpoOut ).x$( -state.width ).willChange().play();
		state.isShow = false;
	}, [ state.isShow, state.width ] );

	const onClickDetail = useCallback( ( e: any ) =>
	{
		navigate( `/detail?id=${props.item.id}`, { state: { id: props.item.id }, replace: false } );
	}, [] );

	const changeWindowSizeEnd = useCallback( ( params: any ) =>
	{
		if( state.isInit )
		{
			updateMenuWidth();
		}
	}, [ updateMenuWidth ] );

	const pushMenuButton = useCallback( () =>
	{
		if( state.isShow )
		{
			closeMenu( { force: true } );
		}
		else
		{
			openMenu();
		}
	}, [ state.isShow, openMenu, closeMenu ] );

	useEventSubscribe(
	[
		[ `CHANGE_WINDOW_SIZE_END`	, changeWindowSizeEnd	],
		[ `PUSH_MENU_BUTTON`		, pushMenuButton		],
		[ `OPEN_MENU`				, openMenu				],
		[ `CLOSE_MENU`				, closeMenu				],
	]);

	useMount( async () =>
	{
		let list = await getFanzaCoupon();
		list = shuffleArray( list );
		state.items	= [];
		for( const item of list )
		{
			state.items.push(
				<div className={`side-menu-item-base`} key={getUniqueKey()}>
					<div className={`side-menu-item-name`}>{item.name}</div>
					<div className={`side-menu-item-image`} key={getUniqueKey()} dangerouslySetInnerHTML={{ __html: item.tag }} />
				</div>
			);
		}
		initMenu();
	});

	const styleParams =
	{
		width			: state.width,
	};
	const params =
	{
		style			: Style( styleParams ),
		items			: state.items,
		onClickDetail	: onClickDetail,
	};
	return ( <Layout {...params} /> );
};
