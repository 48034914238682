import { css } from '@emotion/react';
import { memo } from 'react';

import
{
	COLOR_FANZTOK_MAIN,
} from '../utility/const';
import iconUser from '../assets/icon-user.svg';

const Layout = memo( ( params: any ) =>
{
	return (
		<div css={params.style}>
			<li className={`chat-item-base`}>
				{ params.item.pos === `left` &&
					<>
						<span className={`chat-item-icon`}>
							<img className={`icon-avator`} alt={`avatar`} src={params.item.iconUrl} />
						</span>
						<span className={`chat-item-text-base-left`}>
							<div className={`chat-item-text`}>{params.item.text}</div>
						</span>
					</>
				}
				{ params.item.pos === `right` &&
					<>
						<span className={`chat-item-text-base-right`}>
							<div className={`chat-item-text`}>{params.item.text}</div>
						</span>
						<span className={`chat-item-icon`}>
							<img className={`icon-user`} alt={`avatar`} src={iconUser} />
						</span>
					</>
				}
				{ params.item.pos === `center` &&
					<>
						{ params.item.type === `text` &&
							<span className={`chat-item-text-base-center`}>
								<div className={`chat-item-text`}>{params.item.text}</div>
							</span>
						}
						{ params.item.type === `image` &&
							<div className={`image-content-base`}>
								<a href={params.item.contentUrl} rel={`noopener noreferrer`} target={`_blank`}>
									<img className={`image-content`} alt={`image`} loading={`lazy`} decoding={`async`} content-visibility={`auto`} src={params.item.imageUrl} />
								</a>
							</div>
						}
					</>
				}
			</li>
		</div>
	);
});

const Style = ( params: any ) => css`
	.chat-item-base
	{
		list-style		: none;
		margin			: 0px;
		padding-left	: 16px;
		padding-right	: 16px;
		padding-top		: 16px;
		display			: flex;
		justify-content	: ${params.justifyContent};
	}
	.chat-item-icon
	{
		width			: 60px;
		min-width		: 60px;
		height			: 60px;
	}
	.icon-avator
	{
		position		: relative;
		left			: -100px;
		top				: -98px;
		scale			: 0.25;
		border-radius	: 100%
	}
	.icon-user
	{
		position		: relative;
		left			: -20px;
		top				: -20px;
		scale			: 0.6;
	}
	.chat-item-text-base-left
	{
		position		: relative;
		margin-left		: 12px;
		min-width		: 200px;
		background-color: #f3f3f3;
		color			: #444444;
		line-height		: 1.6;
		border-radius	: 8px;
	}
	.chat-item-text-base-right
	{
		position		: relative;
		margin-right	: 12px;
		min-width		: 200px;
		background-color: #f3f3f3;
		color			: #444444;
		line-height		: 1.6;
		border-radius	: 8px;
	}
	.chat-item-text-base-center
	{
		position		: relative;
		min-width		: 200px;
		background-color: #f3f3f3;
		color			: #444444;
		line-height		: 1.6;
		border-radius	: 8px;
		margin-bottom	: 32px;
	}
	.chat-item-text
	{
		position		: relative;
		padding			: 16px;
	}
	.image-content-base
	{
		position		: relative;
		width			: 100%;

	}
	.image-content
	{
		position		: relative;
		width			: 100%;
		border-radius	: 8px;
		outline			: 1px solid lightgray;
		outline-offset	: -1px;
	}
`;

export const ChatItem = ( props: any ) =>
{
	const styleParams =
	{
		justifyContent	: props.item.pos === `left` ? `flex-start` : ( props.item.pos === `right` ? `flex-end` : `center` ),
	};
	const params =
	{
		style	: Style( styleParams ),
		item	: props.item,
	};
	return ( <Layout {...params} /> );
};
