import { css } from '@emotion/react';
import { memo, useCallback, useRef } from 'react';
import { useUpdate, useMount } from 'react-use';
import TextareaAutosize from 'react-textarea-autosize';

import
{
	BAD_REQUEST_MESSGES,
	COLOR_FANZTOK_MAIN,
	ERROR_MESSGES,
	START_MESSGES,
	WAIT_MESSGES,
	WELCOME_MESSGES,
	FANZAMAN_ICON_URLS,
	OK_MESSGES,
	QUESTION_MESSAGES,
} from '../utility/const';
import
{
	getClientHeight,
	getFanzaCoupon,
	randomRangeInteger,
	sendMessage,
	sleep,
} from '../utility/utility';
import { useLocalState } from '../hook/use-local-state';
import { useEventSubscribe } from '../hook/use-event-subscribe';
import { ChatItem } from './chat-item';
import iconSend from '../assets/icon-send.svg'
import { SideMenu } from './side-menu';

const iconUrl = FANZAMAN_ICON_URLS[ randomRangeInteger( 0, FANZAMAN_ICON_URLS.length ) ];

const Layout = memo( ( params: any ) =>
{
	const ref = useRef< any >();

	if( ref && ref.current )
	{
		params.onRendered( ref.current );
	}

	return (
		<div css={params.style}>
			<ul className={`chat-timeline-base`} ref={ref}>
				{params.items.map( ( item: any, index: number ) => <ChatItem key={index} item={item} /> ) }
			</ul>
			<div className={`chat-footer-base`}>
				<div className={`chat-box-base`}>
					<TextareaAutosize className={`chat-box-input`} autoFocus={true} minRows={1} maxRows={10} placeholder={`お話ししてください`} value={params.text} onChange={params.onChangeText} onKeyDown={params.onKeyDown} />
				</div>
				<img className={`footer-send-button`} src={iconSend} alt={`send`} onClick={params.onClickSend} />
			</div>
			<SideMenu />
		</div>
	);
});

const Style = ( params: any ) => css`
	position			: relative;
	width				: 100%;
	height				: ${params.height}px;
	background-color	: white;
	display				: flex;

.widget-banner
{
	display				: flex;
	position			: relative;
	/* width				: 400px;
	height				: 300px; */
}

	.chat-timeline-base
	{
		position		: absolute;
		width			: 100%;
		height			: calc( ${params.height}px - 40px - 66px - 1px );
		bottom			: calc( 66px + 1px );
		margin			: 0px;
		padding			: 0px;
		overflow-x		: hidden;
		overflow-y		: auto;
	}
	.chat-footer-base
	{
		display			: flex;
		position		: absolute;
		bottom			: 0px;
		width			: calc( 100% - 16px - 16px );
		min-width		: calc( 100% - 16px - 16px );
		padding-left	: 16px;
		padding-right	: 16px;
		background-color: white;
	}
	.footer-send-button
	{
		position		: relative;
		width			: 48px;
		height			: 48px;
		padding-left	: 16px;
		scale			: 0.9;
		cursor			: pointer;
	}
	.footer-send-button:hover
	{
		scale			: 1.0;
	}
	.chat-box-base
	{
		position		: relative;
		bottom			: 0px;
		width			: calc( 100% - 16px - 16px - 32px );
		min-width		: calc( 100% - 16px - 16px - 32px );
		padding-bottom	: 8px;
	}
	.chat-box-input
	{
		position		: relative;
		width			: calc( 100% - 2px - 16px - 16px );
		resize			: none;
		padding			: 16px;
	}
	.chat-box-input:focus
	{
		outline			: 4px auto ${COLOR_FANZTOK_MAIN};
	}
`;

export const Chat = memo( ( props: any ) =>
{
	const state = useLocalState(
	{
		height		: getClientHeight(),
		text		: ``,
		items		: [],
		scrollRef	: null,
		coupons		: [],
	});
	const update = useUpdate();

	const addMessage = async ( pos: string, type: string, text: string, isClear: boolean ) =>
	{
		state.items.push( { pos: pos, type: type, text: text, iconUrl: iconUrl } );
		if( isClear )
		{
			state.text = ``;
		}
		update();
		await sleep( 100 );
		state.scrollRef.scrollTo( { left: 0, top: state.scrollRef.scrollHeight, behavior: `smooth` } );
	};

	const addImage = async ( imageUrl: string, contentUrl: string ) =>
	{
		state.items.push( { pos: `center`, type: `image`, imageUrl: imageUrl, contentUrl: contentUrl, iconUrl: iconUrl } );
		update();
		await sleep( 100 );
		state.scrollRef.scrollTo( { left: 0, top: state.scrollRef.scrollHeight, behavior: `smooth` } );
	};

	const post = async () =>
	{
		if( state.text === `` || String( state.text ).replaceAll( ` `, `` ).replaceAll( `　`, `` ).replaceAll( `\n`, `` ).length === 0 )
		{
			return;
		}
		if( state.text.length < 4 )
		{
			addMessage( `left`, `text`, BAD_REQUEST_MESSGES[ randomRangeInteger( 0, BAD_REQUEST_MESSGES.length ) ], false );
			return;
		}

		const text = state.text;
		addMessage( `right`, `text`, state.text, true );

		const wait = randomRangeInteger( 500, 1000 );
		setTimeout( () =>
		{
			addMessage( `left`, `text`, START_MESSGES[ randomRangeInteger( 0, START_MESSGES.length ) ], false );
		}, wait )

		let handle = setInterval( () =>
		{
			if( handle !== null )
			{
				addMessage( `left`, `text`, WAIT_MESSGES[ randomRangeInteger( 0, WAIT_MESSGES.length ) ], false );
			}
		}, wait + randomRangeInteger( 4000, 6000 ) );

		const timeout = ( ms ) =>
		{
			return new Promise( ( resolve ) => setTimeout( resolve, ms ) );
		};

		// let response = await Promise.race( [ sendMessage( text ), timeout( 15000 ) ] );
		let response = await sendMessage( text );
		if( response )
		{
		}
		else
		{
			response = { type : `error` };
		}

		clearInterval( handle );
		handle = null as any;

		if( response.type === `ok` )
		{
			await sleep( randomRangeInteger( 500, 1000 ) );
			addImage( response.imageUrl, response.contentUrl );
			addMessage( `center`, `text`, response.title, false );

			setTimeout( () =>
			{
				addMessage( `left`, `text`, response.message, false );
			}, randomRangeInteger( 500, 1000 ) );

			const showCoupon = ( words ) =>
			{
				for( const word of words )
				{
					if( word === `` )
					{
						continue;
					}
					for( const coupon of state.coupons )
					{
						for( const keyword of coupon.keywords )
						{
							if( keyword.includes( word ) )
							{
								addMessage( `left`, `text`, <>{word}と言えば今こんなお得案クーポンがあるぞ！「{coupon.name}」だ。どうだ興味ねえか？<br /><br /><div dangerouslySetInnerHTML={{ __html: coupon.tag }} /></>, false );
								return true;
							}
						}
					}
				}
				return false;
			};

			setTimeout( () =>
			{
				if( showCoupon( response.words ) === false )
				{
					addMessage( `left`, `text`, OK_MESSGES[ randomRangeInteger( 0, OK_MESSGES.length ) ], false );
				}
			}, randomRangeInteger( 1500, 3000 ) );
		}
		else // error
		{
			addMessage( `left`, `text`, ERROR_MESSGES[ randomRangeInteger( 0, ERROR_MESSGES.length ) ], false );
		}
	};

	const changeWindowSizeEnd = useCallback( ( params: any ) =>
	{
		state.height = params.height;
		update();
	}, [ state.height, update ] );

	const onChangeText = ( e: any ) =>
	{
		state.text = e.target.value === `\n` ? `` : e.target.value;
		update();
	};

	const onKeyDown = async ( e: any ) =>
	{
		if( e.shiftKey && e.keyCode === 13 )
		{
			post();
		}
	};

	const onClickSend = () =>
	{
		post();
	};

	const onRendered = useCallback( ( ref: any ) =>
	{
		state.scrollRef = ref;
	}, [ state.scrollRef ] );

	useEventSubscribe(
	[
		[ `CHANGE_WINDOW_SIZE_END`, changeWindowSizeEnd ],
	]);

	useMount( async () =>
	{
		addMessage( `center`, `text`, <>FANZAマン、それはFANZA動画を知り尽くした男。<br />その男が見たい動画をあの手この手で紹介してくれるサービスです。<br />「波多野結衣の人妻が見たい！」のように聞いてみてください。</>, false );

		const wait = randomRangeInteger( 1500, 2000 );
		setTimeout( () =>
		{
			addMessage( `left`, `text`, <>{WELCOME_MESSGES[ randomRangeInteger( 0, WELCOME_MESSGES.length ) ]}<br /><br />今ならこんなお得なクーポンがあるぜ！<br /><a href="https://al.dmm.co.jp/?lurl=https%3A%2F%2Fwww.dmm.co.jp%2Fdigital%2F-%2Fwelcome-coupon%2F&af_id=fanztok-001&ch=toolbar&ch_id=text" rel="nofollow" target="_blank">初回購入限定！90%OFFクーポン - FANZA動画</a></>, false );
		}, wait );
		setTimeout( () =>
		{
			addMessage( `left`, `text`, QUESTION_MESSAGES[ randomRangeInteger( 0, QUESTION_MESSAGES.length ) ], false );
		}, wait + randomRangeInteger( 1000, 2000 ) );

		state.coupons = await getFanzaCoupon();
		for( const coupon of state.coupons )
		{
			coupon.keywords = coupon.keyword.split( `,` );
		}
	})

	const styleParams =
	{
		height			: state.height,
	};
	const params =
	{
		style			: Style( styleParams ),
		height			: state.height,
		text			: state.text,
		items			: state.items,
		onChangeText	: onChangeText,
		onKeyDown		: onKeyDown,
		onRendered		: onRendered,
		onClickSend		: onClickSend,
	};
	return ( <Layout {...params} /> );
});
