const events: any    = {};
let delays: any[][]  = [];
let timerHandle: any = null;

const subscribe = ( event: string, fn: any, key: any ) =>
{
	if( event in events === false )
	{
		events[ event ] = [];
	}
	// console.log( `SUBSCRIBE`, event, fn )
	events[ event ].push( { fn: fn, key: key } );
};

const unsubscribe = ( event: string, fn: { toString: () => any; }, key: any ) =>
{
	if( event in events === true )
	{
		if( fn === undefined )
		{
			if( import.meta.env.VITE_BUILD_TYPE === `dev` )
			{
				console.warn( `no UNSUBSCRIBE: undefined: `, event, fn, key )
			}
		}
		let index = -1;
		const fn_ = fn.toString();
		if( key !== undefined )
		{
			index = events[ event ].findIndex( ( item: { fn: { toString: () => any; }; key: any; } ) => ( item.fn.toString() === fn_ && item.key === key ) );
		}
		else
		{
			index = events[ event ].findIndex( ( item: { fn: { toString: () => any; }; } ) => item.fn.toString() === fn_ );
		}
		if( index === -1 )
		{
			if( import.meta.env.VITE_BUILD_TYPE === `dev` )
			{
				console.warn( `no UNSUBSCRIBE: not exist: `, event, fn, key )
			}
		}
		events[ event ].splice( index, 1 );
		if( events[ event ].length === 0 )
		{
			// console.log( `UNSUBSCRIBE`, event, fn )
			delete events[ event ];
		}
	}
};

const publish = ( event: string, params: any, delay: boolean ) =>
{
	let done = false;
	if( event in events === true )
	{
		for( const item of events[ event ] )
		{
			item.fn( params );
			done = true;
		}
	}
	else
	{
		// console.warn( `no PUBLISH`, event )
		if( delay === true )
		{
			delays.push( [ event, params, delay ] );
			if( timerHandle === null )
			{
				timerHandle = setInterval( () => { delayPublish() }, 100 );
			}
		}
	}
	return done;
};

const asyncPublish = async ( event: string, params: any ) =>
{
	if( event in events === true )
	{
		const fns = [];
		for( const item of events[ event ] )
		{
			fns.push( item.fn( params ) );
		}
		return await Promise.all( fns );
	}
	return null;
};

const isSubscribe = ( event: string ) =>
{
	return event in events === true;
};

const dump = () =>
{
	if( import.meta.env.VITE_BUILD_TYPE === `dev` )
	{
		console.log( `EVENTS`, JSON.stringify( events ) )
		console.log( `EVENTS`, events )
	}
}

export const Event =
{
	subscribe	 : ( event: string, fn: any, key: any )								 => subscribe( event, fn, key ),
	unsubscribe	 : ( event: string, fn: { toString: () => any; }, key: any )		 => unsubscribe( event, fn, key ),
	publish		 : ( event: string, params: any = {}, delay: boolean = false )		 => publish( event, params, delay ),
	asyncPublish : async ( event: string, params: any = {}, delay: boolean = false ) => await asyncPublish( event, params ),
	isSubscribe  : ( event: string )												 => isSubscribe( event ),
	dump		 : ()																 => dump(),
};

const delayPublish = () =>
{
	const delays_ = [ ...delays ];
	delays = [];
	let remains = 0;
	for( const delay of delays_ )
	{
		if( publish( delay[ 0 ], delay[ 1 ], delay[ 2 ] ) === false )
		{
			remains++;
		}
	}
	if( remains === 0 )
	{
		clearInterval( timerHandle );
		timerHandle = null;
	}
};
