import { createClient } from '@supabase/supabase-js'
import { isAndroid, isIOS } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import HashMap from 'hashmap';
import parse from 'fast-json-parse';
import stringify from 'fast-json-stable-stringify';
import { cheerio } from 'cheerio';

// import
// {
// 	BREAKPOINT_SP,
// } from './const';

const supabaseUrl	  = `https://xjnjglcvxycftafnqnlf.supabase.co`;
const supabaseAnonKey = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhqbmpnbGN2eHljZnRhZm5xbmxmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2Njg0MTA1MTgsImV4cCI6MTk4Mzk4NjUxOH0.M8Q-JR-rSM1LeYuMjy5t1T6Otd2i0Z9MVSmukhNXCEA`;
export const supabase = createClient( supabaseUrl, supabaseAnonKey );
let uniqueKeyBase	  = uuidv4();
let uniqueKeyCounter  = 0;
let memoryStorage	  = new HashMap();

export const jsonToString = ( json: any ) =>
{
	// return JSON.stringify( json );
	return stringify( json );
};

export const stringToJson = ( string_: string ) =>
{
	// return JSON.parse( string_ );
	const result = parse( string_ );
	if( result.err )
	{
		console.log( `JSON parse エラー` )
		console.log( result.err.message )
		return {};
	}
	return result.value;
};

export const shuffleArray = ( arr: Array< any > ) =>
{
	let i, j, temp;
	arr = arr.slice();
	i = arr.length;
	if( i === 0 )
	{
		return arr;
	}
	while( --i )
	{
		j = Math.floor( Math.random() * ( i + 1 ) );
		temp = arr[ i ];
		arr[ i ] = arr[ j ];
		arr[ j ] = temp;
	}
	return arr;
};

export const toNumber = ( str: any ) =>
{
	return Number( str );
}

export const toInteger = ( str: any ) =>
{
	return parseInt( str );
}

export const isBrower = () =>
{
	return typeof window !== `undefined`;
};

export const isSmartphone = () =>
{
	return isAndroid || isIOS;
}

export const randomRangeInteger = ( min: number, max: number ) =>	// min <= N < max
{
	return Math.floor( Math.random() * ( max - min ) + min );
};

export const sleep = ( msec: number ) =>
{
	return new Promise<void>( ( resolve ) =>
	{
		setTimeout( () => { resolve() }, msec );
	});
};

export const waitUntil = async ( condition: any, wait = 0, retry = 0 ) =>
{
	if( condition() === false )
	{
		return;
	}
	return new Promise( ( resolve: any ) =>
	{
		let count = 0;
		const id = setInterval( () =>
		{
			const until = retry === 0 || ++count <= retry;
			if( condition() === false || until === false )
			{
				clearInterval( id );
				resolve()
			}
		}, wait );
	});
};

export const getLocalStorage = ( item: string ) =>
{
	if( isBrower() === true )
	{
		return localStorage.getItem( item );
	}
	return null;
};
export const setLocalStorage = ( item: string, value: string ) =>
{
	if( isBrower() === true )
	{
		localStorage.setItem( item, value );
	}
};
export const removeLocalStorage = ( item: string ) =>
{
	if( isBrower() === true )
	{
		localStorage.removeItem( item );
	}
};

export const getSessionStorage = ( item: string ) =>
{
	if( isBrower() === true )
	{
		return sessionStorage.getItem( item );
	}
	return null;
};
export const setSessionStorage = ( item: string, value: string ) =>
{
	if( isBrower() === true )
	{
		sessionStorage.setItem( item, value );
	}
};
export const removeSessionStorage = ( item: string ) =>
{
	if( isBrower() === true )
	{
		sessionStorage.removeItem( item );
	}
};

export const setMemoryStorage = ( item: string, value: string ) =>
{
	memoryStorage.set( item, value );
};
export const getMemoryStorage = ( item: string ) =>
{
	const value = memoryStorage.get( item );
	if( value !== undefined )
	{
		return value;
	}
	return undefined;
};
export const removeMemoryStorage = ( item: string ) =>
{
	const value = memoryStorage.get( item );
	if( value !== undefined )
	{
		memoryStorage.delete( item );
	}
};
export const clearMemoryStorage = () =>
{
	memoryStorage = new HashMap();
	// sendToServiceWorker( `clear-memory-cache`, `` );
};

export const parseUrlParams = () =>
{
	const params = [];
	if( isBrower() === true )
	{
		const urlParams = new URLSearchParams( window.location.search );
		for( let pair of urlParams.entries() )
		{
			params.push( { key: pair[ 0 ], value: pair[ 1 ] } );
		}
	}
	return params;
};

export const getUrlParams = ( key: string ) =>
{
	const result = parseUrlParams().find( ( item ) => item.key === key );
	if( result !== undefined )
	{
		return result.value;
	}
	return undefined;
};

export const getClientWidth = () =>
{
	if( isBrower() === true )
	{
		return document.body.clientWidth;
	}
	return 0;
};

export const getClientHeight = () =>
{
	if( isBrower() === true )
	{
		return document.body.clientHeight;
	}
	return 0;
};

export const getUniqueKey = ( isReset: boolean ) =>
{
	if( isReset )
	{
		uniqueKeyBase	 = uuidv4();
		uniqueKeyCounter = 0;
	}
	return `${uniqueKeyBase}${uniqueKeyCounter++}`;
};

export const supabaseSelectData = async ( table: string, key: string, value: any ) =>
{
	const { data, error } = await supabase.from( table ).select().eq( key, value );
	if( error !== null )
	{
		if( import.meta.env.DEV )
		{
			console.log( `supabaseSelectData: error: `, error )
		}
		return [];
	}
	return data;
};

export const supabaseSelectText = async ( table: string, key: string, value: any ) =>
{
	const { data, error } = await supabase.from( table ).select().textSearch( key, value );
	if( error !== null )
	{
		if( import.meta.env.DEV )
		{
			console.log( `supabaseSelectText: error: `, error )
		}
		return [];
	}
	return data;
};

export const sendMessage = async ( prompt: string ) =>
{
	const response = await supabase.functions.invoke( `chat`, { body: { prompt: prompt, uuid: uniqueKeyBase } } );
	if( response.data === null || response.data.info === `` || response.data.info === null || response.error )
	{
		return ``;
	}

// console.log( `sortType ==========` )
// console.log( response.data.info.sortType )
// console.log( `message ==========` )
// console.log( response.data.message )
// console.log( `ワード==========` )
// console.log( response.data.words )	// undefined の場合がある
// console.log( `女優==========` )
// console.log( response.data.info.iteminfo.actress )
// console.log( `時間==========` )
// console.log( response.data.info.volume )
// console.log( `レビュー==========` )
// console.log( response.data.info.review )	// undefined の場合がある

	const result =
	{
		type		: `ok`,
		text		: response.data.info.title,
		imageUrl	: response.data.info.imageURL.large,
		contentUrl	: response.data.info.affiliateURL,
		contentId	: response.data.info.content_id,
		title		: `${response.data.info.title} [作品番号:${response.data.info.content_id}]`,
		message		: response.data.message,
		words		: response.data.words.split( ` ` ),
	};
	return result;
};

export const getFanzaCoupon = async () =>
{
	const url	 = `https://sheets.googleapis.com/v4/spreadsheets/1WCLeIKrisyuWwz731rUvFeSUeVukYgvffPNGJLMURqw/values/coupon?key=AIzaSyDO-xCbCNedgzAQEEXsaiGnjpyeba3CErY`;
	const result = await fetch( url );
	const text	 = await result.text();
	const list	 = JSON.parse( text ).values;
	const items	 = [];
	for( let i = 1; i < list.length; i++ )
	{
		items.push(
		{
			no		: list[ i ][ 0 ],
			name	: list[ i ][ 1 ],
			keyword	: list[ i ][ 2 ],
			tag		: list[ i ][ 3 ],
		});
	}
	return items;
};
