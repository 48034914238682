import { css } from '@emotion/react';
import { memo, useCallback } from 'react';
import { navigate } from '@reach/router';

import
{
	COLOR_FANZTOK_MAIN,
	COLOR_ULTRA_LIGHT_GRAY,
} from '../utility/const';

const Layout = memo( ( params: any ) =>
{
	return (
		<div css={params.style}>
			<div className={`info-base`}>
				<div className={`description`}>このページは存在しません。他のページを閲覧しましょう。</div>
			</div>
			<div className={`button-base`}>
				<button className={`button`} onClick={params.onClick}>トップへ戻る</button>
			</div>
		</div>
	);
});

const Style = ( params: any ) => css`
	position				: relative;
	width					: 100%;
	height					: 100vh;
	background-color		: ${COLOR_ULTRA_LIGHT_GRAY};

	.info-base
	{
		text-align			: center;
		padding-left		: 40px;
		padding-right		: 40px;
	}
	.description
	{
		padding-top			: 100px;
		font-size			: 14px;
		color				: gray;
	}
	.button-base
	{
		padding-top			: 60px;
		text-align			: center;
		background-color	: ${COLOR_ULTRA_LIGHT_GRAY};
	}
	.button
	{
		width				: 120px;
		background-color	: ${COLOR_FANZTOK_MAIN};
		color				: white;
		font-size			: 12px;
		transform			: scale( 1.0 );
		transition			: all 0.1s 0s ease;
	}
	.button:hover
	{
		transform			: scale( 1.03 );
	}
`;

export const Page404 = memo( ( props ) =>
{
	const onClick = useCallback( () =>
	{
		navigate( `/`, { state: {}, replace: false } );
	}, [] );

	const styleParams =
	{
	};
	const params =
	{
		style	: Style( styleParams ),
		onClick	: onClick,
	};
	return ( <Layout {...params} /> );
});
